export const LOCALE_MAP = {
  en: 'English',
  de: 'German',
  ar: 'Arabic',
  cz: 'Czech',
  es: 'Spanish',
  fr: 'French',
  ko: 'Korean',
  sv: 'Swedish',
  zh_CN: 'Chinese',
}
